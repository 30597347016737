import request from '@/utils/request'
import CrudAppService from '@/framework/CrudAppService'
import {
  WhrDto,
  CreateOrUpdateWhrDto,
  GetListWhrDto
} from '@/framework/models/Whr'
import {Guid} from 'guid-typescript'

export default class WhrApi extends CrudAppService <WhrDto, WhrDto, Guid, GetListWhrDto,
  CreateOrUpdateWhrDto, CreateOrUpdateWhrDto> {
  constructor() {
    super('/keep-fit/whr');
  }
}
