import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface WhrDto extends ExtensibleEntityDto <Guid> {
  whrMin: number
  whrMax: number
  conclusion: string
  remark: string
  sex: number
}

export class CreateOrUpdateWhrDto {
  public whrMin: number | undefined
  public whrMax: number | undefined
  public conclusion: string = ''
  public remark: string = ''
  public sex: number | undefined
}

export class GetListWhrDto extends PagedAndSortedResultRequestDto {
}
